html {
  font-size: 20px;
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 700px) {
  html {
    font-size: 18px;
  }
}

.w-notification {
  width: 375px;
}

.icon {
  fill: none;
  stroke: #6b46c1;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 24px;
}

.red-icon {
  stroke: #c53030;
}

button:hover > .icon,
a:hover > .icon {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 24px;
}

@media screen and (min-width: 768px) {
  .md\:max-w-200 {
    max-width: 200px;
  }
}
